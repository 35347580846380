.nav {
	display: block;
	width: 50vh;
	position: fixed;
	top: 0;
	right: -1000px;
	background-color: white;
	z-index: 99991;
	padding: 0 1rem;
	box-shadow: -5px 0 10px -5px rgba(black, .2);

	& button {
		margin-right: 1rem;

		& i {
			line-height: inherit;
		}
	}

	& .nav-flow {
		min-height: 50vh;
		height: 100vh;
	}

	& .nav-titulo {
		color: $gray1;
		font-size: 1.7rem;
		font-weight: 600;
		padding: 1.5rem 1rem;
		@include border-box;
		text-transform: uppercase;
	}

	& .nav-menu {
		color: $gray1;
		font-size: 1.4rem;
		margin: 0;
		padding: .6rem 1rem;
		@include border-box;
		border-left: solid 2px transparent;
		border-bottom: solid 2px $gray5;
		transition: all .25s;
		&:hover {
			transition: all .25s;
			border-left: solid 2px $colorPrimary;
			padding: .6rem 1.5rem;
		}
	}
}

@media all and (min-width: 1024px) and (max-width: 1500px){
	.nav {
		display: block;
		width: 65vh;
		position: fixed;
		top: 0;
		right: -1000px;
		background-color: white;
		z-index: 99991;
		padding: 0 1rem;
		box-shadow: -5px 0 10px -5px rgba(black, .2);
		overflow: auto;
		& button {
			margin-right: 1rem;
	
			& i {
				line-height: inherit;
			}
		}
	
		& .nav-flow {
			min-height: 50vh;
			height: 100vh;
		}
	
		& .nav-titulo {
			color: $gray1;
			font-size: 1.7rem;
			font-weight: 600;
			padding: 1.5rem 1rem;
			@include border-box;
			text-transform: uppercase;
		}
	
		& .nav-menu {
			color: $gray1;
			font-size: 1.4rem;
			margin: 0;
			padding: .6rem 1rem;
			@include border-box;
			border-left: solid 2px transparent;
			border-bottom: solid 2px $gray5;
			transition: all .25s;
			&:hover {
				transition: all .25s;
				border-left: solid 2px $colorPrimary;
				padding: .6rem 1.5rem;
			}
		}
	}
}

@media all and (max-width: 1023px){
	.nav{
		width: 100%;
		overflow: auto;
		& .nav-menu {
			color: $gray1;
			font-size: 1.4rem;
			margin: 0;
			padding: .6rem 1rem;
			@include border-box;
			border-left: solid 2px transparent;
			border-bottom: solid 2px $gray5;
			transition: all .25s;
			&:hover {
				transition: all .25s;
				border-left: solid 2px transparent;
				padding: .6rem 1rem;
			}
		}
		& .nav-flow {
			min-height: 50vh;
			height: 110vh;
		}
		& .content {
			padding-bottom: 10rem !important;
		}
	}
}