.section_ca{
    display: block;
    width: 100%;
    & .img{
        display: block;
        width: 30%;
        text-align: center;
        & img{
            width: 100%;
        }
    }
    & .conteudo1{
        display: block;
        width: 100%;
        max-width: 60rem;
        text-align: center;
        & h1{
            color: white;
            font-size: 4.5rem;
            font-weight: 800;
        }
        & p{
            color: black;
            font-weight: 800;
            font-size: 1.1rem;
            text-align: center;
        }

        & .btn_contato{
            margin: auto;
            display: block;
            width: 50%;
            max-width: 50%;
            & p{
                color: $gray1;
                font-size: 1.3rem;
                font-weight: 800;
                background-color: white;
                border-radius: .2rem;
                padding: 1rem 2rem;
            }
        }

        & h2{
            color: white;
            font-size: 2.5rem;
            font-weight: 800;
        }

        & .ul_li{
           
            & .img2{
                width: 18%;
                display: inline-block;
                text-align: left;
                vertical-align: middle;
                & img{
                    width: 100%;
                }
            }

            & .ps{
                display: inline-block;
                vertical-align: middle;
                width: 82%;
                padding: 0 2rem 0 1rem;
                & .title{
                    text-align: left;
                    color: #ff9169;
                    font-size: 1.1rem;
                    font-weight: 800;
                }
                & p{
                    text-align: left;
                    color: white;
                    font-size: 1rem;
                    font-weight: 400;
                }
            }
                   
        }
    }
}

.sectioninscrever{
    background-color: #FF9169;
    display: block;
    width: 100%;
    & .section_ca2{
        display: block;
        max-width: 60rem;
        margin: auto;
        & .p1{
            color: white;
            font-size: 1.5rem;
            font-weight: 800;
            padding-right: 2rem;
        }
        & .p2{
            color: white;
            font-size: 1.1rem;
            font-weight: 400;
        }

        & .btn_inscreva{
            display: block;
            width: 17rem;
            & p{
                text-align: center;
                color: $gray1;
                font-size: 1.2rem;
                font-weight: 700;
                background-color: white;
                border-radius: .2rem;
                padding: 1rem 1.5rem;
                line-height: 2rem;
            }
        }
    }
}

@media all and (max-width: 1023px){
    .section_ca{
        display: block;
        width: 100%;
        & .img{
            display: block;
            width: 100%;
            text-align: center;
            & img{
                width: 100%;
            }
        }
        & .conteudo1{
            display: block;
            width: 100%;
            max-width: 60rem;
            text-align: center;
            & h1{
                color: white;
                font-size: 3.1rem;
                font-weight: 800;
            }
            & p{
                color: black;
                font-weight: 800;
                font-size: 1.2rem;
                text-align: center;
            }
    
            & .btn_contato{
                margin: auto;
                display: block;
                width: 90%;
                max-width: 100%;
                & p{
                    color: $gray1;
                    font-size: 1.3rem;
                    font-weight: 800;
                    background-color: white;
                    border-radius: .2rem;
                    padding: 1rem 2rem;
                }
            }
    
            & h2{
                color: white;
                font-size: 2rem;
                font-weight: 800;
            }
    
            & .ul_li{
               
                & .img2{
                    width: 18%;
                    display: inline-block;
                    text-align: left;
                    vertical-align: middle;
                    & img{
                        width: 100%;
                    }
                }
    
                & .ps{
                    display: inline-block;
                    vertical-align: middle;
                    width: 82%;
                    padding: 0 2rem 0 1rem;
                    & .title{
                        text-align: left;
                        color: #ff9169;
                        font-size: 1.2rem;
                        font-weight: 800;
                    }
                    & p{
                        text-align: left;
                        color: white;
                        font-size: 1.2rem;
                        font-weight: 400;
                    }
                }
                       
            }
        }
    }

    .sectioninscrever{
        background-color: #FF9169;
        display: block;
        width: 100%;
        & .section_ca2{
            display: block;
            max-width: 60rem;
            margin: auto;
            & .p1{
                color: white;
                font-size: 1.5rem;
                font-weight: 800;
                padding-right: 0;
                text-align: center;
            }
            & .p2{
                color: white;
                font-size: 1.1rem;
                font-weight: 400;
                text-align: center;
            }
    
            & .btn_inscreva{
                display: block;
                width: 17rem;
                & p{
                    text-align: center;
                    color: $gray1;
                    font-size: 1.2rem;
                    font-weight: 700;
                    background-color: white;
                    border-radius: .2rem;
                    padding: 1rem 1.5rem;
                    line-height: 2rem;
                }
            }
        }
    }
}