.credipronto_section_interna{
	display: block;
	width: 90%;
	background-color: #EBF6FF;
	padding: 2rem 1rem;
	margin: 0;
	border-radius: .2rem;
	& .texto{
		display: inline-block;
		width: 100%;
		vertical-align: middle;
		& h2{
			padding: 0 0 0 3rem;
			color: $colorTerciary;
			font-size: 2rem;
			font-weight: 800;
			z-index: 100;
			line-height: 4.5rem;
		}
		& p{
			padding: 0 0 0 3rem;
			font-size: 1.3rem;
			font-weight: 500;
			color: $gray3;
		}
		& a{
			& p{
				display: block;
				width: 15rem;
				height: 4rem;
				background-color: #003563;
				color: white;
				font-size: 1.3rem;
				font-weight: 800;
				padding: 1rem 1rem;
				border-radius: .2rem;
				text-align: center;
			}
		}
	}
}

@media all and (max-width: 1023px){
	.credipronto_section_interna{
		display: block;
		width: 100%;
		background-color: #EBF6FF;
		padding: 1rem;
		margin: auto;
		border-radius: .2rem;
		& .texto{
			display: inline-block;
			width: 100%;
			vertical-align: middle;
			& h2{
				padding: 0 1rem;
				color: $colorTerciary;
				font-size: 1.7rem;
				font-weight: 800;
				z-index: 100;
				line-height: 2.5rem;
			}
			& p{
				padding: 0 1rem;
				font-size: 1rem;
				font-weight: 500;
				color: $gray3;
			}
			& a{
				& p{
					display: block;
					width: 12rem;
					height: fit-content;
					background-color: #003563;
					color: white;
					font-size: 1.1rem;
					font-weight: 800;
					padding: 1rem 1rem;
					border-radius: .2rem;
					text-align: center;
				}
			}
		}
	}
}