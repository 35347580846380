/* SCROLL-BAR */
::-webkit-scrollbar{width: 8px; height: 10px;}
::-webkit-scrollbar-track-piece{background-color: $gray5;}
::-webkit-scrollbar-thumb:vertical{height: 10px; background-color: darken($colorTerciary, 5);}
::-webkit-scrollbar-thumb:horizontal{width: 8px; background-color: darken($colorTerciary, 5);}
::-ms-clear {width: 0; height: 0;}
::-ms-reveal {width: 0; height: 0;}

.ho-title{
	font-size: 1.3rem;
}

.ho-subtitle{
	font-size: 1.1rem;
}

.ho-txt{
	font-size: 1rem;
}

.ho-mini{
	font-size: 0.9rem;
	margin-top: 3px;
    margin-bottom: 3px;
}

.hover_scale{
	transition: all .15s;
	&:hover{
		transform: scale(1.2);
	}
}

.font-style {
	font-family: 'Lopes Sans';
	font-size: 2.4rem;
}

.bg_section_imoveis{
	background-color: white;
}

.btn_ver_mais{
	text-align: center;
	width: fit-content;

	& p{
		color: $colorTerciary;
		font-weight: 800;
		font-size: 1.1rem;
		transition: all .2s;
		&:hover{
			color: $colorPrimary;
		}
	}
}

.under_p{
	text-align: center;
	width: 4.5rem;
	height: 4px;
	margin: auto;
	background-color: $colorPrimary;
}

html{
	width: 100%;
	height: 100%;
	touch-action: pan-y;
	-ms-touch-action: pan-y;
}

body{
	font-family: 'Open Sans', sans-serif;
	background-color: white;
	color: $gray2;
	font-weight: 500;
	margin: 0;
	padding: 0;
	font-size: 1rem;
	touch-action: pan-y;
	-ms-touch-action: pan-y;
}

.politica_formularios{
	width: 100%;
	display: block;
	
	& p{
		text-align: left;
		color: $gray2;
		font-weight: 400;
		font-size: .8rem;
		user-select: none;
		& a{
			color: $colorPrimary;
			font-weight: 500;
			font-size: .8rem;
			user-select: none;
			&:hover{
				text-decoration: underline;
			}
		}
	}

	& label{
		text-align: left;
		color: $gray2;
		font-weight: 400;
		font-size: .8rem;
		user-select: none;
	}
}

.u-title{
	padding-bottom: 2px;
    text-decoration: none;
    border-bottom: 3px solid $colorPrimary;
}

.u-title2{
	padding-bottom: 2px;
    text-decoration: none;
    border-bottom: 3px solid white;
}

/* .specialfont{
	font-family: 'Rosmatika';
	font-size: 3rem;
	line-height: 2.5rem;
	color: black;
}

.specialfontwhite{
	font-family: 'Rosmatika';
	font-size: 2.5rem;
	line-height: 2.5rem;
	color: white;
	text-shadow: 2px 1px 3px rgba(0,0,0,.6);
} */


h1, .h1{
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
	color: $gray1;
}

h2, .h2{
	font-size: 1.4rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
	color: $gray1;
}

h3, .h3{
	font-size: 1.3rem;
	font-weight: 500;
	line-height: 1.1em;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
}


h4, .h4{
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.1em;
	color: $gray2;
	padding: 0;
	margin: 0.8rem 0 0.8rem 0;
}

a{
	font-size: 1rem;
	text-decoration:none;
	cursor:pointer;
	color: $gray1;

	&:hover {
		color: white;
	}
	&:visited, &:focus, &:active{
		color: $gray1;
	}
}

div{
	line-height: 1em;
	margin: auto;
}

p{
	font-weight: 300;
	color: $gray1;
	line-height: 1.4em;
	margin: 10px 0;
	font-size: 1rem;
}

.icon-gradiente {
	@include gradient('left, #{lighten($colorSecondary, 5%)} 30%, #{darken($colorPrimary, 8%)}' 60%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.icon-animscale{
	&:hover{
		transform: scale(1.2);
		transition: .15s all;
		@include border-box;
	}
}
.border-rad{
	/* border-radius: 10px !important; */
}
.detail-line::after {
	display: block;
	width: 2rem;
	content: '';
	height: .2rem;
	background-color: $colorPrimary;
}

.detail-dialog:after {
	display: block;
	width: 5rem;
	content: '';
	height: .3rem;
	margin-top: 1rem;
	background-color: $colorDanger;
}
.padrao-icone {
	& i {
		font-size: 45px;
		width: 70px;
		height: 70px;
		line-height: 70px;
		@include border-radius(50%);
		text-align: center;
		color: $gray1;
		border: 2px solid $gray1;
	}
}

.site-space-2-5 {
	display: table;
	width: 100%;
	height: 2.5rem; 
}

.container-fixo {
	position: fixed !important;
	bottom: 35px !important;
	right: 35px !important;
	z-index: 9997;

	& #ux_to_top{
		display: block;
		z-index: 9999;
		margin: auto;
		top: 0 !important;
		left: 0 !important;
	}

	& .whatsapp_container{
		margin-top: 1rem;
		display: block;
		width: 65px;
		height: 65px;
		border: none;
		border-radius: 50%;
		background-color: #00af53;
		cursor: pointer;
		position: relative;
		box-shadow: 0 0 0 0 rgba(#25d366, .5);
		animation: pulse 1.5s infinite;

		@keyframes pulse {
			0% {
				@include transform(scale(.9));
			}
			70% {
				@include transform(scale(1));
			box-shadow: 0 0 0 25px rgba(#25d366, 0);
			}
			100% {
				@include transform(scale(.9));
				box-shadow: 0 0 0 0 rgba(#25d366, 0);
			}
		}

		& p{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
			& i{
				font-size: 2rem;
				color: white;
			}
		}

	}

	& #wpp_corretores{
		width: 320px;
		position: absolute;
		bottom: 0;
		right: 80px;
		background-color: white;
		border-radius: 2.5rem;
		box-shadow: 0 0 10px 1px rgba(black, .15);
		cursor: auto;
		
		& .miniatura_corretor{
			padding: 1rem;
			display: block;
			width: 100%;
			border-bottom: solid 1px lighten($gray4, 15);
			
			& .img{
				width: 20%;
				display: inline-block;
				vertical-align: middle;
				
				& img{
					width: 100%;
					border-radius: 50%;
				}
			}

			& .info_corretor{
				width: 80%;
				display: inline-block;
				vertical-align: middle;
				padding-left: .5rem;
				position: relative;

				& p{
					position: inherit;
					top: inherit;
					left: inherit;
					transform: inherit;
					text-align: left;
					padding: 1rem;
					border-radius: 1rem;
					margin: 0;
					color: $gray1;
					font-size: .9rem;
					font-weight: 500;
					transition: all .2s ease-in-out;
					cursor: pointer;

					& span{
						font-size: .8rem;
						font-weight: 400;
						color: $gray2;
						width: 85%;
						display: block;
					}
					
					& i{
						font-size: 2rem;
						position: absolute;
						right: -5%;
						top: 50%;
						transform: translate(-5%, -50%);
						color: transparent;
						transition: all .2s ease-in-out;
					}
					&:hover{
						background-color: lighten($gray4, 20);
						padding-left: 1.5rem;

						& i{
							right: 5%;
							color: #00af53;
						}
					}
				}

			}
			
			&:last-of-type{
				border-bottom: none;
			}
		}

	}
}

.animation_in{
	display: block !important;
	animation: normal animation_in .3s ease-in-out;
}
.animation_in > .whatsapp_container{
	animation: none;
}

@keyframes animation_in{
	from{
		filter: opacity(0);
		bottom: -200px;
	}
	to {
		filter: opacity(1);
		bottom: 0;
	}
}

.animation_out{
	animation: normal animation_out .3s ease-in-out;
}

@keyframes animation_out{
	from{
		filter: opacity(1);
		bottom: 0;
	}
	to {
		filter: opacity(0);
		bottom: -200px;
	}
}

// .container-fixo {
// 	display: block;
// 	width: 100%;
// 	position: fixed;
// 	bottom: 3rem;
// 	right: 3rem;
// 	z-index: 9;
// 	text-align: right;
// }

.b-tohome {}

.strong, strong, b{
	font-weight: 600 !important;
}

.danger {
	color: red !important;
}
.primary{
	color: $colorPrimary !important;
}
.secondary{
	color: $colorSecondary !important;
}
.terciary{
	color: $colorTerciary !important;
}
.red{
	color: $colorDanger !important;
}
.black{
	color: $gray1 !important;
}
.gray1{
	color: $gray1 !important;
}
.gray2{
	color: $gray2 !important;
}
.gray3{
	color: $gray3 !important;
}
.gray4{
	color: $gray4 !important;
}
.gray5{
	color: $gray5 !important;
}
.bg-c-white {
	background-color: #ffffff !important;
}
.bg-white{
	display: table;
	width: 100%;
	background-color: #ffffff;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray1{
	display: table;
	width: 100%;
	background-color: $gray1;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray2{
	display: table;
	width: 100%;
	background-color: $gray2;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray3{
	display: table;
	width: 100%;
	background-color: $gray3;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray4{
	display: table;
	width: 100%;
	background-color: $gray4;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-gray5{
	display: table;
	width: 100%;
	background-color: $gray5;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-primary{
	display: table;
	width: 100%;
	background-color: $colorPrimary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}

.bg-primary-g{
	display: table;
	width: 100%;
	@include gradient('right, #{lighten($colorPrimary, 5%)} 5%, #{darken($colorPrimary, 8%)}' 60%);
}

.bg-secondary{
	display: table;
	width: 100%;
	background-color: $colorSecondary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-terciary{
	display: table;
	width: 100%;
	background-color: $colorTerciary;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.bg-danger{
	display: table;
	width: 100%;
	background-color: $colorDanger;
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
}
.box-lateral {
	margin-left: -30px;
	margin-right: -30px;
}
.box-lateral-20 {
	margin-left: -20px;
	margin-right: -20px;
}

.bg-gradiente{
	display: table;
	width: 100%;
	background: -webkit-gradient(linear, left top, right top, from($claro), to($escuro)) no-repeat;
	background: -moz-linear-gradient(left, $claro, $escuro);
	filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=1, StartColorStr=$claro, EndColorStr=$escuro);
}

.creccss select{
	color: white !important;
	padding: 0.5rem;
	border: solid 1px $colorTerciary !important;
	font-size: .9rem;
	height: calc(2.3rem - 2px);
	width: 100%;
	background-color: $colorTerciary;
	text-align: center;
	cursor: pointer;
	&:hover{
		background-color: darken($colorTerciary, 5);
	}
}

.bg-gradiente2{
	display: table;
	position: relative;
	left: 40%;
	width: 30%;
	height: 100%;
	z-index: 0;
	background: linear-gradient(90deg, transparent,$colorQuarterly 50%);
	//background: -moz-linear-gradient(left, $colorPrimary, $colorDanger);
	//filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=1, StartColorStr=$colorPrimary, EndColorStr=$colorDanger);
}

.bg-gradiente3{
	display: table;
	position: relative;
	right: 40%;
	width: 20%;
	height: 100%;
	z-index: 0;
	background: linear-gradient(270deg, transparent,white 90%);
	//background: -moz-linear-gradient(left, $colorPrimary, $colorDanger);
	//filter:progid:DXImageTransform.Microsoft.Gradient(GradientType=1, StartColorStr=$colorPrimary, EndColorStr=$colorDanger);
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin site--large(){

	html{
		font-size: 1.15vw;
	}
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin site--medium(){

	html{
		font-size: 1.3vw;
	}
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin site--tablet-landscape(){

	html{
		font-size: 1.7vw;
	}

}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin site--tablet-portrait(){
	html{
		font-size: 2vw;
	}
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin site--smart-landscape(){
	html{
		font-size: 2vw;
	}
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin site--smart-portrait(){

	html{
		font-size: 3.75vw;
	}
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin site--smart-old(){

	html{
		font-size: 3.75vw;
	}
}

@media all and (max-width:1023px){

	.container-fixo {
		bottom: 15px !important;
		right: 15px !important;

		&  #wpp_corretores{
			width: 260px;
		}
	}
	.font-style {
		font-family: 'Lato';
		font-size: 1.7rem;
		color: white;
		padding-top: 1rem;
		
	}
	.specialfontwhite{
		font-size: 1.9rem;
	}

	.splide__pagination {
		bottom: 7.5rem !important;
	}
}

@media all and (min-width: 1024px) and (max-width: 1366px) {
	.splide__arrow--next {
		right: 2rem !important;
	}

	.splide__arrow--prev {
		left: 2rem !important;
	}
}